.terms-of-service-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.terms-of-service-header {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
}
.terms-of-service-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1324px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.terms-of-service-branding {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.terms-of-service-image {
  width: 200px;
  object-fit: cover;
}
.terms-of-service-desktop-menu {
  display: flex;
}
.terms-of-service-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.terms-of-service-button {
  width: 100%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-primary);
}
.terms-of-service-button:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.terms-of-service-text {
  font-style: normal;
  font-family: "Lora";
  font-weight: 500;
}
.terms-of-service-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.terms-of-service-hamburger {
  width: 100px;
  object-fit: cover;
}
.terms-of-service-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #fff;
}
.terms-of-service-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.terms-of-service-branding1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.terms-of-service-text1 {
  color: rgba(18, 40, 33, 1);
  font-size: 24px;
  line-height: 36px;
}
.terms-of-service-icon {
  width: 7px;
  height: 7px;
}
.terms-of-service-text2 {
  color: rgba(18, 40, 33, 1);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
}
.terms-of-service-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.terms-of-service-icon02 {
  width: 34px;
  height: 34px;
}
.terms-of-service-links1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.terms-of-service-button1 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.terms-of-service-button2 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.terms-of-service-button3 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.terms-of-service-social-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  margin-top: auto;
  justify-content: flex-start;
}
.terms-of-service-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.terms-of-service-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.terms-of-service-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.terms-of-service-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.terms-of-service-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 991px) {
  .terms-of-service-navbar-interactive {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .terms-of-service-icon {
    display: none;
  }
  .terms-of-service-text2 {
    display: none;
  }
}
@media(max-width: 767px) {
  .terms-of-service-desktop-menu {
    display: none;
  }
  .terms-of-service-burger-menu {
    width: 40px;
    height: 40px;
    display: flex;
    aspect-ratio: 1;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #E6F9E0;
  }
  .terms-of-service-hamburger {
    width: 16px;
    height: 8px;
  }
  .terms-of-service-button1 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .terms-of-service-button2 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .terms-of-service-button3 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .terms-of-service-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .terms-of-service-button {
    width: 100%;
  }
  .terms-of-service-social-bar {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
