.steps1-container {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.steps1-text {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 2.5em;
  font-style: normal;
  font-weight: 500;
}
.steps1-max-width {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.steps1-container1 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.steps1-container2 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.steps1-image {
  width: 76px;
  height: 76px;
}
.steps1-text01 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-style: normal;
  font-family: "Lora";
  font-weight: 500;
}
.steps1-text02 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  text-align: center;
  font-family: "Lato";
}
.steps1-text03 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.steps1-container3 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
  justify-content: center;
}
.steps1-image1 {
  width: 76px;
  height: 76px;
}
.steps1-text04 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-style: normal;
  font-family: "Lora";
  font-weight: 500;
}
.steps1-text05 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  text-align: center;
  font-family: "Lato";
}
.steps1-text06 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.steps1-container4 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.steps1-container5 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
  justify-content: center;
}
.steps1-image2 {
  width: 76px;
  height: 76px;
}
.steps1-text07 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-style: normal;
  font-family: "Lora";
  font-weight: 500;
}
.steps1-text08 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  text-align: center;
  font-family: "Lato";
}
.steps1-text09 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.steps1-container6 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
  justify-content: center;
}
.steps1-image3 {
  width: 76px;
  height: 76px;
}
.steps1-text10 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-style: normal;
  font-family: "Lora";
  font-weight: 500;
}
.steps1-text11 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  text-align: center;
  font-family: "Lato";
}
.steps1-text12 {
  top: var(--dl-space-space-unit);
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
@media(max-width: 991px) {
  .steps1-max-width {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .steps1-container1 {
    flex-direction: column;
  }
  .steps1-container2 {
    width: 100%;
  }
  .steps1-container3 {
    width: 100%;
  }
  .steps1-container4 {
    flex-direction: column;
  }
  .steps1-container5 {
    width: 100%;
  }
  .steps1-container6 {
    width: 100%;
  }
}
