.branding-branding {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.branding-image {
  width: 200px;
  object-fit: cover;
}
