.content-list11-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.content-list11-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.content-list11-content {
  align-self: stretch;
  align-items: flex-start;
}
.content-list11-text004 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text020 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text028 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text036 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text049 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text067 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text075 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text083 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text091 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text109 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text127 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text176 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list11-text183 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
