.pricing10-pricing23 {
  gap: 0;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing10-max-width {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.pricing10-content {
  gap: 0;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
}
.pricing10-container {
  width: 10%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing10-column1 {
  gap: var(--dl-space-space-twounits);
  width: 15%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-theme-1-primary);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  border-top-width: 0px;
}
.pricing10-price {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-price01 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-text {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-text01 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  width: auto;
  font-size: 36px;
  text-align: center;
  font-family: "Lora";
}
.pricing10-text02 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-button {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.pricing10-button:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.pricing10-text03 {
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.pricing10-column2 {
  gap: var(--dl-space-space-twounits);
  width: 15%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-1-primary);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent1);
  border-top-width: 0px;
}
.pricing10-price02 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-price03 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-text04 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-text05 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 36px;
  text-align: center;
  font-family: "Lora";
}
.pricing10-text06 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-button1 {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.pricing10-button1:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.pricing10-text07 {
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.pricing10-column3 {
  gap: var(--dl-space-space-twounits);
  width: 15%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-1-primary);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: #E6F9E0;
  border-top-width: 0px;
}
.pricing10-price04 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-price05 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-text08 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-text09 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 36px;
  text-align: center;
  font-family: "Lora";
}
.pricing10-text10 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-button2 {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.pricing10-button2:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.pricing10-text11 {
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.pricing10-column31 {
  gap: var(--dl-space-space-twounits);
  width: 15%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-1-primary);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: #E6F9E0;
  border-top-width: 0px;
}
.pricing10-price06 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-price07 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-text12 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-text13 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 36px;
  text-align: center;
  font-family: "Lora";
}
.pricing10-text14 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-button3 {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.pricing10-button3:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.pricing10-text15 {
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.pricing10-column32 {
  gap: var(--dl-space-space-twounits);
  width: 15%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-1-primary);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: #E6F9E0;
  border-top-width: 0px;
}
.pricing10-price08 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-price09 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-text16 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-text17 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 36px;
  text-align: center;
  font-family: "Lora";
}
.pricing10-text18 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-button4 {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.pricing10-button4:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.pricing10-text19 {
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.pricing10-column33 {
  gap: var(--dl-space-space-twounits);
  width: 15%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-1-primary);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: #E6F9E0;
  border-top-width: 0px;
}
.pricing10-price10 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-price11 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing10-text20 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-text21 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 36px;
  text-align: center;
  font-family: "Lora";
}
.pricing10-text22 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Lora";
  font-weight: 600;
}
.pricing10-button5 {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.pricing10-button5:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.pricing10-text23 {
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.pricing10-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing10-content2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing10-text24 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 20px;
  font-family: "Lora";
}
.pricing10-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 0px;
  flex-direction: row;
  border-left-width: 0px;
}
.pricing10-container03 {
  flex: 0 0 auto;
  width: 10%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing10-container04 {
  flex: 1;
  width: 15%;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-1-primary);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.pricing10-text25 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  width: 20px;
  font-family: "Lora";
}
.pricing10-container05 {
  flex: 1;
  width: 15%;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-1-primary);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.pricing10-text26 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-family: "Lora";
}
.pricing10-container06 {
  flex: 1;
  width: 15%;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-1-primary);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.pricing10-text27 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  height: auto;
  align-self: center;
  font-family: "Lora";
}
.pricing10-container07 {
  flex: 1;
  width: 15%;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-1-primary);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.pricing10-text28 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  height: auto;
  align-self: center;
  font-family: "Lora";
}
.pricing10-container08 {
  flex: 1;
  width: 15%;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-1-primary);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.pricing10-text29 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  height: auto;
  align-self: center;
  font-family: "Lora";
}
.pricing10-container09 {
  flex: 1;
  width: 15%;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-1-primary);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.pricing10-text30 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  height: auto;
  align-self: center;
  font-family: "Lora";
}
.pricing10-root-class-name {
  margin-top: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .pricing10-content {
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .pricing10-content {
    flex-direction: column;
  }
  .pricing10-column1 {
    width: 100%;
    border-top-width: 1px;
  }
  .pricing10-text01 {
    font-size: 24px;
  }
  .pricing10-column2 {
    width: 100%;
  }
  .pricing10-text05 {
    font-size: 24px;
  }
  .pricing10-column3 {
    width: 100%;
  }
  .pricing10-text09 {
    font-size: 24px;
  }
  .pricing10-column31 {
    width: 100%;
  }
  .pricing10-text13 {
    font-size: 24px;
  }
  .pricing10-column32 {
    width: 100%;
  }
  .pricing10-text17 {
    font-size: 24px;
  }
  .pricing10-column33 {
    width: 100%;
  }
  .pricing10-text21 {
    font-size: 24px;
  }
  .pricing10-container01 {
    display: none;
  }
}
@media(max-width: 479px) {
  .pricing10-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pricing10-column1 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing10-text01 {
    font-size: 16px;
  }
  .pricing10-button {
    width: 100%;
  }
  .pricing10-column2 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing10-text05 {
    font-size: 16px;
  }
  .pricing10-button1 {
    width: 100%;
  }
  .pricing10-column3 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing10-text09 {
    font-size: 16px;
  }
  .pricing10-button2 {
    width: 100%;
  }
  .pricing10-column31 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing10-text13 {
    font-size: 16px;
  }
  .pricing10-button3 {
    width: 100%;
  }
  .pricing10-column32 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing10-text17 {
    font-size: 16px;
  }
  .pricing10-button4 {
    width: 100%;
  }
  .pricing10-column33 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing10-text21 {
    font-size: 16px;
  }
  .pricing10-button5 {
    width: 100%;
  }
}
