.content-list1-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.content-list1-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.content-list1-content {
  align-self: stretch;
  align-items: flex-start;
}
.content-list1-text001 {
  font-style: normal;
  font-weight: 600;
}
.content-list1-text009 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text018 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text027 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text036 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text045 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text054 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text063 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text072 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text081 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text090 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.content-list1-text099 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
