.features23-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.features23-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.features23-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.features23-text {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 2.5em;
  font-style: normal;
  font-weight: 500;
}
.features23-container2 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features23-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: 0px;
}
.features23-text01 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 30px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 500;
}
.features23-text02 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  text-align: left;
  font-family: "Lato";
}
.features23-button {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.features23-button:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.features23-text03 {
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.features23-container3 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-1-secondary);
}
.features23-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: 0px;
}
.features23-text06 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 30px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 500;
}
.features23-text07 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  text-align: left;
  font-family: "Lato";
}
.features23-button1 {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.features23-button1:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.features23-container4 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features23-image2 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: 0px;
}
.features23-text09 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 30px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 500;
}
.features23-text10 {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  font-size: 16px;
  text-align: left;
  font-family: "Lato";
}
.features23-button2 {
  width: 95%;
  transition: 0.3s;
  border-color: var(--dl-color-theme-1-primary);
  text-decoration: none;
  background-color: var(--dl-color-theme-1-primary);
}
.features23-button2:hover {
  fill: var(--dl-color-theme-1-primary);
  color: var(--dl-color-theme-1-primary);
  border-color: var(--dl-color-theme-1-primary);
  background-color: var(--dl-color-theme-1-secondary);
}
.features23-text11 {
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
.features23-image3 {
  width: 100%;
  object-fit: cover;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 10%;
  padding-right: 10%;
}
.features23-text14 {
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-family: "Lora";
  font-weight: 400;
}
@media(max-width: 991px) {
  .features23-max-width {
    flex-direction: column;
  }
  .features23-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .features23-container {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .features23-container2 {
    width: 100%;
  }
  .features23-container3 {
    width: 100%;
  }
  .features23-container4 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .features23-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .features23-button {
    width: 100%;
  }
  .features23-button1 {
    width: 100%;
  }
  .features23-button2 {
    width: 100%;
  }
}
